<template>
  <div id="data-list-list-view" class="data-list-container">
    <h3>Opgaver</h3>

    <vs-table
      ref="table"
      class="mt-8"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="employees"
      @selected="onTaskClicked(selected)"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                employees.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : employees.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>100</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="displayName">Navn</vs-th>
        <vs-th sort-key="email">Email</vs-th>
        <vs-th sort-key="profile">Job Titel</vs-th>
        <!-- <vs-th sort-key="department">Ansat Afdeling</vs-th> -->
        <vs-th sort-key="createDate">Oprettet</vs-th>
        <vs-th sort-key="">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <!-- <p><b>{{ tr.ansatFinansenhed}} - {{ tr.ansatFinansenhedNavn }}</b></p> -->
              <p class="product-name font-medium truncate">
                {{ tr.name }}
              </p>
            </vs-td>
            <vs-td>
              <p>{{ tr.email }}</p>
            </vs-td>
            <vs-td>
              <p>{{ getProfileName(tr.profile) }}</p>
            </vs-td>

            <vs-td>
              <p>{{ tr.createDate | formatDate }}</p>
            </vs-td>

            <vs-td style="width:500px">
              <p><b>{{ tr.ansatFinansenhed}} - {{ tr.ansatFinansenhedNavn }}</b></p>
              <vs-button
                v-for="department in tr.departmentStatus"
                :key="department.name"
                disabled
                :color="getStatusColor(department.state)"
                type="line"
                :icon="getStatusIcon(department.state)"
                class="float-left ml-4"
                style="margin-left:0px !important; margin-right: 1em"
              >
                {{ department.name }}
              </vs-button>

            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  MinusCircleIcon,
  CircleIcon,
  HelpCircleIcon
} from "vue-feather-icons";
export default {
  components: {
    CheckCircleIcon,
    MinusCircleIcon,
    CircleIcon,
    HelpCircleIcon
  },
  data() {
    return {
      query: "*",

      employees: [],
      profiles: [],

      selected: [],
      itemsPerPage: 20,
      isMounted: false,

      activeConfirm: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.employees.length;
    }
  },
  methods: {
    getStatusIcon(state) {
      switch (state) {
        case 0:
          return "radio_button_unchecked";

        case 2:
          return "help_outline";

        case 3:
          return "check_circle_outline";

        default:
          return "radio_button_unchecked";
      }
    },
    getStatusColor(state) {
      if (state === 0 || state === undefined) {
        return "black";
      }
      if (state === 2) {
        return "orangered";
      }
      if (state === 3) {
        return "green";
      }
    },
    getProfileName(id) {
      try {
        return this.profiles.find(x => x.profileId === id).type;
      } catch (error) {
        return "";
      }
    },
    getEmployees: function() {
      this.$vs.loading();

      this.$http
        .post(process.env.VUE_APP_API + "/employees/QueryByRole", this.$store.state.roles)
        .then(resp => {
          resp.data.forEach(employee => {
            employee.departmentStatus.forEach(department => {
              if (this.$store.state.roles.includes(department.name)) {
                // var percentage = this.getCompletionStatus(department);
                employee[department.name + "Status"] = department.state;
              }
            });
          });

          this.employees = resp.data;
          console.log(`${resp.data.length} employees fetched` + this.query);
        })

        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    getProfiles: function() {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/profiles?query=" + this.query)
        .then(resp => {
          this.profiles = resp.data;
          console.log(`${resp.data.length} profiles fetched`);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    onTaskClicked(employee) {
      this.$router
        .push({ name: "task", params: { employeeId: employee.employeeId } })
        .catch(() => {});
    },

    getDepartmantStatusText(departmentStatus) {
      var incompleteDepartments = departmentStatus
        .filter(r => r.state != 3)
        .flatMap(r => r.name);
      return (
        incompleteDepartments.join(", ") + " mangler at behandle oprettelsen"
      );
    },
    getCompletionStatus(departmentStatus) {
      return departmentStatus.state == 3 ? 100 : 0;
    }
  },
  created() {
    this.getProfiles();
    this.getEmployees();
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.draft {
  padding: 0 !important;
  text-align: center;
}

// .draft-text {
// display: inline-block;
// -webkit-transform: rotate(270deg);
// -moz-transform: rotate(270deg);
// -o-transform: rotate(270deg);
// transform: rotate(270deg);
// font-size: small !important;
// }
</style>
